import logo from './logo.svg';
import './App.css';

function App() {
  const emailAddress = "contact@tazrinshop.com";
  const gmailComposeLink = `https://mail.google.com/mail/u/0/?view=cm&fs=1&tf=1&to=${encodeURIComponent(emailAddress)}`;

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <div className='contact-info'>
          <h1>Tazrin Shop</h1>
          <p>
          <h2>Contact Info</h2>
            <b>Email:</b> <a href={gmailComposeLink} target="_blank">{emailAddress}</a>
          </p>
        </div>
         <h3>Website under construction...</h3>
      </header>
    </div>
  );
}

export default App;